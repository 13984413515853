import React from 'react';
import AllBlogArticles from 'views/AllBlogArticles';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import { graphql } from 'gatsby';

const AllBlogs = ({ data }): JSX.Element => {
	return (
		<WithLayout
			component={AllBlogArticles}
			layout={Main}
			data={data.allContentfulBlogPost}
		/>
	);
};

export default AllBlogs;

export const query = graphql`
	query allBlogPosts {
		allContentfulBlogPost(
			filter: { blog_post: { elemMatch: { datePosted: { ne: null } } } }
		) {
			edges {
				node {
					blogContent {
						raw
					}
					datePosted
					title
					id
					slug
					subtitle
					tags
					imageCover {
						id
						fluid {
							src
							srcSet
						}
					}
					author {
						authorName
						authorImage {
							fluid {
								src
								srcSet
							}
						}
					}
				}
			}
		}
	}
`;
