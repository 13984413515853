import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { BlogPostsList } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sidebarNewsletter: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
}));

const AllBlogArticles = ({ data }): JSX.Element => {
  const classes = useStyles();

  const detailedBlogPosts = data.edges.map(data => {
    return {
      cover: {
        src: data.node.imageCover.fluid.src,
        srcSet: data.node.imageCover.fluid.srcSet,
      },
      title: data.node.title,
      subtitle: data.node.subtitle,
      author: {
        photo: {
          src: data.node.author.authorImage.fluid.src,
          srcSet: data.node.author.authorImage.fluid.srcSet,
        },
        name: data.node.author.authorName,
      },
      date: data.node.datePosted,
      tags: data.node.tags ? data.node.tags : [],
      slug: data.node.slug,
    };
  });

  return (
    <div className={classes.root}>
      <Section>
        <BlogPostsList data={detailedBlogPosts} />
      </Section>
    </div>
  );
};

export default AllBlogArticles;
